import axios from "axios"

export const createCategory = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "post",
        url: "https://estimating.candrelec.com/api/app/part-category",
        data
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const createSystem = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "post",
        url: "https://estimating.candrelec.com/api/app/part-system",
        data
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const createSubSystem = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "post",
        url: "https://estimating.candrelec.com/api/app/part-sub-system",
        data
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const createStyle = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "post",
        url: "https://estimating.candrelec.com/api/app/part-style",
        data
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const createOwnerType = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "post",
        url: "https://estimating.candrelec.com/api/app/part-owner-type",
        data
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const createManufacturer = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "post",
        url: "https://estimating.candrelec.com/api/app/part-manufacturer",
        data
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const createPricingUnit = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "post",
        url: "https://estimating.candrelec.com/api/app/part-pricing-unit",
        data
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const createCostCode = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "post",
        url: "https://estimating.candrelec.com/api/app/part-cost-code",
        data
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getAllOwnerType = () => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "get",
        url: "https://estimating.candrelec.com/api/app/part-owner-type",
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getAllPricingUnits = () => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "get",
        url: "https://estimating.candrelec.com/api/app/part-pricing-unit",
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getAllManufacturers = () => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "get",
        url: "https://estimating.candrelec.com/api/app/part-manufacturer",
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getAllCostCode = () => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "get",
        url: "https://estimating.candrelec.com/api/app/part-cost-code",
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getAllCategories = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/app/part-category",
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getSystemsByCategoryId = (categoryId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetSystemsByCategoryId?categoryId=${categoryId}`,
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getSubSystemsBySystemId = (systemId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetSubSystemsBySystemId?systemId=${systemId}`,
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getStylesBySubSystemId = (subSystemId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetStylesBySubSystemId?subSystemId=${subSystemId}`,
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createPartService = (data, manufacturerId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreatePartVersion?ManufacturerId=${manufacturerId}`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createElementService = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateElementVersion`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createBundleService = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateBundle`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createGroupService = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateGroup`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getAllParts = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllPartsVersions?apiUrl=https://estimating.candrelec.com/api`,
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getAllElements = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllElementVersions?apiUrl=https://estimating.candrelec.com/api`,
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getAllBundles = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllBundles?apiUrl=https://estimating.candrelec.com/api`,
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}