import axios from "axios"

export const registerUser = () => {
    return new Promise((resolve, reject) => {
      axios({
        headers: { 'Content-Type': 'application/json'
        },
        data: {
            "userName": "testDragon",
            "emailAddress": "userDragon@example.com",
            "password": "Test123!",
            "appName": "test"
          },
        method: "post",
        url: "https://estimating.candrelec.com/api/account/register"
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const loginUser = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: { 'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: {
          "username": "user111@example.com",
          "password": "Biz@3714324",
          "grant_type": "password"
        },
      method: "post",
      url: "https://estimating.candrelec.com/auth/connect/token"
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}