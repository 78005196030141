<template>
    <!-- <input id="file-upload" type="file" @change="openDocument" class="btn" />
    <button @click="saveChanges" class="btn btn-primary">Make changes</button>
    <button @click="createPDF" class="btn btn-primary">Create PDF</button>

    <div class="pdf-container"></div> -->


    <div class="col-sm-12 mt-5">
        <template>
  <div class="pdf-container"></div>
</template>

        <div v-if="!isShowViewer" class="card">


            <div class="card-header">
                <h5>Load a document</h5>
            </div>
            <div class="card-body">
                <div v-if="allHubs.length > 0" class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="exampleFormControlSelect9">All hubs</label>
                            <select @change="onChangeHub" v-model="selectedHub" class="form-select digits"
                                id="exampleFormControlSelect9">
                                <option disabled>Select Hub</option>
                                <option :value="allHubs[0]?.id">
                                    {{ allHubs[0]?.attributes.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div v-if="allProjects.length > 0" class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="exampleFormControlSelect9">All Projects</label>
                            <select @change="onChangeProject" v-model="selectedProject" class="form-select digits" id="">
                                <option disabled>Select Project</option>
                                <option v-for="project in allProjects" :value="project">
                                    {{ project?.attributes.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div v-if="allFolders.length > 0" class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="exampleFormControlSelect9">All Folders</label>
                            <select @change="onChangeFolder" v-model="selectedFolder" class="form-select digits" id="">
                                <option disabled>Select Folder</option>
                                <option v-for="folder in allFolders" :value="folder">
                                    {{ folder?.attributes.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div v-if="allSubFolders.length > 0" class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="exampleFormControlSelect9">All Sub Folders</label>
                            <select @change="onChangeSubFolder" v-model="selectedSubFolder" class="form-select digits"
                                id="">
                                <option disabled>Select Sub Folder</option>
                                <option v-for="folder in allSubFolders" :value="folder">
                                    {{ folder?.attributes.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div v-if="allDocuments.length > 0" class="row">
                    <div class="col">
                        <div class="mb-3">
                            <label class="form-label" for="exampleFormControlSelect9">All Documents</label>
                            <select @change="onChangeDocument" v-model="selectedDocument" class="form-select digits" id="">
                                <option disabled>Select Document</option>
                                <option v-for="document in allDocuments" :value="document">
                                    {{ document?.attributes.displayName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button class="btn btn-primary btn-block m-2" v-if="isShowViewer" @click="closeViewer">Select Another Modal</button>
        <div id="MyViewerDiv"></div>

    </div>
</template>

<script setup>
import { ref, watch, inject, computed, onBeforeMount, onMounted } from "vue"
import { getAllHubs, getAllProjects, getProjectContents, getDocumentDetail, translateDocument } from "@/services/autodesk"
import { extractTextFromPdf } from "@/services/project"

import PSPDFKit from "pspdfkit";


const pdfContainer = ref(null);
const pdfFile = ref("/document.pdf");
let instance = null
const blobToSend = ref(null);
const annotationData = ref({});

const saveChanges = async () => {
    const documentBuffer = await instance.exportPDF();
    const blob = new Blob([documentBuffer], { type: 'application/pdf' });
    const formData = new FormData();
    formData.append("file", blob);
    blobToSend.value = blob
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'document.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

const openDocument = (event) => {
    // Revoke the previous blob URL if it exists
    if (pdfFile.value && pdfFile.value.startsWith('blob:')) {
        window.URL.revokeObjectURL(pdfFile.value);
    }
    // Create a new blob URL for the selected file
    pdfFile.value = window.URL.createObjectURL(event.target.files[0]);
    loadPSPDFKit()
};

const loadPSPDFKit = async () => {
    PSPDFKit.unload('.pdf-container');
    instance = await PSPDFKit.load({
        licenseKey: "I2zkBGeTMZFlDRyGwoqgQT_wb3kAiqxxt71vQdS6jp368yB0A6CQ6tUwL4oOuYCDkmCSTHnZ0SzRI1gsB5CtD2tpgqd9xL1Fyfmo7AweYc8dSnBNUDO80fY8-QO7mkX-v5XqIncsBXJtztUrlm4gx8gu-inuShfTcW1K4thVEJCHh7N-nnczM3I4TkSlkrE75a98RR61dHw5Dt3D",
        document: pdfFile.value,
        container: '.pdf-container',
    });
    // Add event listener to capture rectangle annotations
    instance.addEventListener("annotations.create", (event) => {
        const pageIndex = instance.viewState.currentPageIndex;
  const pageRect = getPageVisibleRect(pageIndex);
  // Traverse page annotations and check if their bounding box
  // overlaps the visible area.
  instance.getAnnotations(pageIndex).then((annotations) => {
    annotations.forEach((annotation) => {
      if (annotation.boundingBox.isRectOverlapping(pageRect)) {
        // Visible annotation detected. Log it (or keep a reference to it somewhere).
        console.log(annotation.toJS());
        annotationData.value = annotation.toJS()
      }
    });
  });
    });
    console.log("PSPDFKit has loaded", instance)
    //   emit('loaded', instance);
    return instance;
};

const getPageVisibleRect = (pageIndex) => {
  // Page DOM element.
  const pageEl = instance.contentDocument.querySelector(
    `.PSPDFKit-Page[data-page-index="${pageIndex}"]`
  );
  const pageBoundingClientRect = pageEl.getBoundingClientRect();
  // Viewport DOM element.
  const viewportEl = instance.contentDocument.querySelector(
    ".PSPDFKit-Viewport"
  );
  // Toolbar DOM element, which needs offsetting.
  const toolbarEl = instance.contentDocument.querySelector(
    ".PSPDFKit-Toolbar"
  );
  // Get the visible page area in page units.
  return instance.transformContentClientToPageSpace(
    new PSPDFKit.Geometry.Rect({
      left: Math.max(pageBoundingClientRect.left, 0),
      top: Math.max(pageBoundingClientRect.top, toolbarEl.scrollHeight),
      width: Math.min(pageEl.clientWidth, viewportEl.clientWidth),
      height: Math.min(pageEl.clientHeight, viewportEl.clientHeight)
    }),
    pageIndex
  );
}

const createPDF = () => {
    const pageNumber = annotationData.value.pageIndex + 1
    const pdfFormData = new FormData();
    pdfFormData.append("pageNumber", pageNumber)
    pdfFormData.append("x",  annotationData.value.boundingBox.left)
    pdfFormData.append("y",  annotationData.value.boundingBox.top)
    pdfFormData.append("width", annotationData.value.boundingBox.width)
    pdfFormData.append("height",  annotationData.value.boundingBox.height)
    const file = new File([blobToSend.value], "fileName - Test", { type: blobToSend.value.type, lastModified: Date.now() });
    pdfFormData.append("PdfFile", file)
    extractTextFromPdf(pdfFormData)
    .then(res => {
      console.log("res", res)
    })
    .catch(err => {
      console.error(err)
    })
};

// PSPDFKit.unload(".pdf-container");
onMounted(() => {
    loadPSPDFKit();
});

// watch(() => props.pdfFile, (newVal) => {
//       if (newVal) {
//         loadPSPDFKit();
//       }
//     });

// onBeforeUnmount(() => {
//   PSPDFKit.unload('.pdf-container');
// });

var viewer;
var options = {
    env: 'AutodeskProduction',
    accessToken: localStorage.getItem('access_token')
};

const allHubs = ref([])
const selectedHub = ref('')

const allProjects = ref([])
const selectedProject = ref({})

const allFolders = ref([])
const selectedFolder = ref({})

const allSubFolders = ref([])
const selectedSubFolder = ref({})

const allDocuments = ref([])
const selectedDocument = ref({})

const documentURN = ref('')
const translateDocumentURN = ref('')

const isShowViewer = ref(false)

onBeforeMount(() => {
    fetchAllHubs()
})

const fetchAllHubs = () => {
    getAllHubs()
        .then(res => {
            allHubs.value = res.data
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

const onChangeHub = () => {
    fetchAllProjects()
}

const onChangeProject = () => {
    fetchAllDocuments()
}

const onChangeSubFolder = () => {
    const folderId = selectedSubFolder.value.id
    getProjectContents(selectedProject.value.id, folderId)
        .then(res => {
            allDocuments.value = res.data
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

const onChangeDocument = () => {
    const documentId = selectedDocument.value.id
    getDocumentDetail(selectedProject.value.id, documentId)
        .then(res => {
            documentURN.value = res.data.relationships.tip.data.id
            console.log('documentURN', documentURN.value)
            onTranslateDocument()
        })
        .catch(err => {
            console.error(err)
        })
}

const onTranslateDocument = () => {
    const payload = {
        "input": {
            "urn": btoa(documentURN.value),
            "compressedUrn": true,
            "rootFilename": "A5.iam"
        },
        "output": {
            "formats": [
                {
                    "type": "svf",
                    "views": [
                        "2d",
                        "3d"
                    ]
                }
            ]
        }
    }

    translateDocument(payload)
        .then(res => {
            translateDocumentURN.value = res.urn
            console.log('res', res)
            startViewer()
        })
        .catch(err => {
            console.error(err)
        })
}

const startViewer = () => {
    var documentId = `urn:${translateDocumentURN.value}`;
    Autodesk.Viewing.Initializer(options, function onInitialized() {
        Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
        console.log("viewer 1", viewer)
    });
}

const closeViewer = () => {
    viewer.finish();
    viewer = null;
    // Autodesk.Viewing.shutdown();
    isShowViewer.value = false

}

const onDocumentLoadSuccess = (doc) => {

    // A document contains references to 3D and 2D viewables.

    doc.downloadAecModelData()
        .then(aecModelData => {
            console.log('AEC Model Data:', aecModelData);
            if (aecModelData && aecModelData.levels) {
                const levels = aecModelData.levels;
                levels.forEach(level => {
                    console.log(`Level Name: ${level.name}, Elevation: ${level.elevation}`);
                });
            }
        })
        .catch(error => {
            console.error('Error downloading AEC Model Data:', error);
        });

    var viewerDiv = document.getElementById('MyViewerDiv');
    viewer = new Autodesk.Viewing.Private.GuiViewer3D(viewerDiv);
    viewer.start();
    // isShowViewer.value = true

    const filter = {
        "root_condition": "or", // "and" (default) or "or" //*/

        "property_query": {
            "$or": [
                // { "$like": [ "?name", "'%Level 1%'" ] },
                // { "$like": ["s.props.p30db51f9", "'%Floor%'"] },
                // { "$like": [ "?name", "'%Wall%'" ] },
                // { "$like": ["s.props.p30db51f9", "'%Wall%'" ] },
                { "?Base Constraint": "'Level 1'" },
            ]
        } //*/
    };

    var defaultModel = doc.getRoot().getDefaultGeometry();
    viewer.loadDocumentNode(doc, defaultModel, { filter })


    viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, function () {
        // var instanceTree = viewer.model.getData().instanceTree;
        // const rootId = instanceTree.getRootId();
        // viewer.hide(1)

        // console.log('instanceTree', instanceTree)


        highlightLevels(viewer, ['Level 1']);





        // extractLevels(viewer.model);
    });

    //   viewer.loadDocumentNode(doc, defaultModel, {
    //     filter: {
    //         "property_query": [{"?Base Constraint": "'Level 1'"}]
    //     }
    // }).then(() => {
    //     // Once the model is loaded, highlight specific levels
    //     viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, function () {
    //         highlightLevels(viewer, ['Level 2']); // Adjust levels to highlight here
    //     });
    // });



    // var viewables = Autodesk.Viewing.Document.getSubItemsWithProperties(doc.getRootItem(), { 'type': 'geometry' }, true);
    // if (viewables.length === 0) {
    //     console.error('Document contains no viewables.');
    //     return;
    // }

    // const pdfPages = viewables.length / 2
    // console.log("viewables", Autodesk.Viewing.Document)
    // // Choose any of the avialble viewables
    // var initialViewable = viewables[0];
    // var svfUrl = doc.getViewablePath(initialViewable);
    // var modelOptions = {
    //     sharedPropertyDbPath: doc.getPropertyDbPath(),
    //     isAEC: true
    // };
    // console.log("modelOptions", modelOptions)

    // var viewerDiv = document.getElementById('MyViewerDiv');
    // viewer = new Autodesk.Viewing.Private.GuiViewer3D(viewerDiv);
    // viewer.start(svfUrl, modelOptions);
    // isShowViewer.value = true

    // viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, function () {
    //     var instanceTree = viewer.model.getData().instanceTree;
    //     console.log('instanceTree', instanceTree)
    //     getLevels(viewer);
    //     // extractLevels(viewer.model);
    // });

    //getLevels(viewer);

    // model.getExternalIdMapping(function(externalIdMapping) {
    //     var levels = [];

    //     // Traverse the model structure to find levels
    //     model.getInstanceTree().enumerate(function(dbId) {
    //         var name = model.getNodeName(dbId);
    //         var parent = model.getNodeParentId(dbId);

    //         // Check if this node represents a level
    //         if (name.toLowerCase().includes("level")) {
    //             var level = {
    //                 dbId: dbId,
    //                 name: name,
    //                 parentId: parent
    //             };
    //             levels.push(level);
    //         }
    //     });

    //     // Output the levels
    //     console.log("Levels found:", levels);
    // });
}

const highlightLevels = (viewer, levelNames) => {
    const searchPromises = levelNames.map(levelName => {
        return new Promise((resolve, reject) => {
            viewer.search(levelName, resolve, reject, ['name']);
        });
    });

    Promise.all(searchPromises).then(results => {
        const dbIds = results.flat();
        var instanceTree = viewer.model.getData().instanceTree;
        console.log('Found dbIds:', dbIds);

        const levelID = dbIds.filter((x) => instanceTree.getNodeName(x).includes("Level"))
        console.log("levelID", levelID)
        viewer.hide(levelID)
        // dbIds.forEach(dbId => {
        //     viewer.setThemingColor(dbId, new THREE.Vector4(1, 0, 0, 1)); // Red color
        // });
    }).catch(error => {
        console.error('Error searching for levels:', error);
    });
};

const extractLevels = (model) => {
    if (!model) {
        console.error('Model is null');
        return;
    }

    model.getPropertyDb().executeUserFunction(userFunction).then(result => {
        const levels = result.levels;
        console.log('Levels:', levels);
    }).catch(error => {
        console.error('Error getting levels:', error);
    });
}

const userFunction = (pdb) => {
    const dbIds = pdb.getRootIds();
    const levels = [];

    function findLevels(dbId) {
        pdb.enumObjects(dbId, function (childId) {
            const name = pdb.getObjectName(childId);
            const properties = pdb.getObjectProperties(childId);

            properties.forEach(prop => {
                if (prop.displayName === 'Level') {
                    levels.push({
                        id: childId,
                        name: prop.displayValue,
                        elevation: prop.attributes.find(attr => attr.displayName === 'Elevation').displayValue
                    });
                }
            });

            findLevels(childId);
        });
    }

    dbIds.forEach(findLevels);

    return { levels };
}

const getLevels = (viewer) => {
    // const hamza = Autodesk.Viewing.Document.getAecModelData(viewer.model.getDocumentNode())

    var levels = [];
    viewer.loadExtension('Autodesk.AEC.LevelsExtension')
    console.log('extension', viewer.getExtension('Autodesk.Aec.LevelsExtension'))
    //     const aecdata = viewer.model.getDocumentNode().getAecModelData()
    //   console.log("aecdata", aecdata)

    viewer.getObjectTree(function (tree) {
        tree.enumNodeChildren(tree.getRootId(), function (dbId) {
            var node = tree.getNodeName(dbId);
            console.log("tree", tree)

            // Check if this node represents a level
            if (node.toLowerCase().includes("level")) {
                var level = {
                    dbId: dbId,
                    name: node
                };
                levels.push(level);
            }
        }, true);

        // Output the levels
        console.log("Levels found:", levels);
    });
}


const onDocumentLoadFailure = (viewerErrorCode) => {
    console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode);
}

// function onDocumentLoadSuccess(doc) {

//     // A document contains references to 3D and 2D viewables.
//     var viewables = Autodesk.Viewing.Document.getSubItemsWithProperties(doc.getRootItem(), { 'type': 'geometry' }, true);
//     if (viewables.length === 0) {
//         console.error('Document contains no viewables.');
//         return;
//     }

//     // Choose any of the avialble viewables
//     var initialViewable = viewables[0];
//     var svfUrl = doc.getViewablePath(initialViewable);
//     var modelOptions = {
//         sharedPropertyDbPath: doc.getPropertyDbPath()
//     };

//     var viewerDiv = document.getElementById('MyViewerDiv');
//     viewer = new Autodesk.Viewing.Private.GuiViewer3D(viewerDiv);
//     viewer.start(svfUrl, modelOptions, onLoadModelSuccess, onLoadModelError);
// }

// /**
//  * Autodesk.Viewing.Document.load() failuire callback.
//  */
// function onDocumentLoadFailure(viewerErrorCode) {
//     console.error('onDocumentLoadFailure() - errorCode:' + viewerErrorCode);
// }

// /**
//  * viewer.loadModel() success callback.
//  * Invoked after the model's SVF has been initially loaded.
//  * It may trigger before any geometry has been downloaded and displayed on-screen.
//  */
// function onLoadModelSuccess(model) {
//     console.log('onLoadModelSuccess()!');
//     console.log('Validate model loaded: ' + (viewer.model === model));
//     console.log(model);
// }

// /**
//  * viewer.loadModel() failure callback.
//  * Invoked when there's an error fetching the SVF file.
//  */
// function onLoadModelError(viewerErrorCode) {
//     console.error('onLoadModelError() - errorCode:' + viewerErrorCode);
// }

const onChangeFolder = () => {
    const folderId = selectedFolder.value.id
    getProjectContents(selectedProject.value.id, folderId)
        .then(res => {
            allSubFolders.value = res.data
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

const fetchAllProjects = () => {
    getAllProjects(selectedHub.value)
        .then(res => {
            allProjects.value = res.data
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

const fetchAllDocuments = () => {
    const folderId = selectedProject.value.relationships.rootFolder.data.id
    getProjectContents(selectedProject.value.id, folderId)
        .then(res => {
            allFolders.value = res.data
            console.log('res', res)
        })
        .catch(err => {
            console.error(err)
        })
}

</script>

<style scoped>
.pdf-container {
    height: 100vh;
}
</style>