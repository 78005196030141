<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-7 b-center bg-size" :style="{ backgroundImage: 'url('+ require('../../assets/images/login/2.jpg') +')' }" style="background-size: cover; background-position: center center; display: block;">
                <img class="bg-img-cover bg-center" src="../../assets/images/login/2.jpg" alt="looginpage" style="display: none;" />
            </div>
            <div class="col-xl-5 p-0">
                <div class="login-card">
                    <div>
                        <div class="login-main ">
                            <form class="theme-form" >
                                <h4>Sign in to account</h4>
                                <p>Enter your email & password to login</p>
                                <div class="form-group">
                                    <label class="col-form-label">Email Address</label>
                                    <input class="form-control" type="email" required="" placeholder="Test@gmail.com" />
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">Password</label>
                                    <div class="form-input position-relative">
                                        <input class="form-control" :type="active?'password':'text'" name="login[password]"  required="" placeholder="*********" />
                                        <div class="show-hide"><span :class="active?'show':'hide'"  @click.prevent="show"></span></div>
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <div class="checkbox p-0">
                                        <input id="checkbox1" type="checkbox" />
                                        <label class="text-muted" for="checkbox1">Remember password</label>
                                    </div>
                                    <router-link to="/">  
                                    <button class="btn btn-primary btn-block w-100">
                                    Sign in 
                                     </button></router-link>
                                </div>
                            </form>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    export default {
        data(){
            return{
                active: true
            }
        },
        methods:{
            show(){
                this.active=!this.active
            }
        }
    }
    </script>
    