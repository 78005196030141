<template>
  <router-view />
</template>

<script setup>
import { onBeforeMount } from "vue";
import { getAccessToken } from "@/services/autodesk"
import { loginUser } from "@/services/auth"
import axios from "axios"


onBeforeMount(() => {
  // const token = "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijg0QUM3MjRGRTQyNkZFQjMyNUM1RjQwQUZDQTgwMTU0NUZCOTI5RDAiLCJ4NXQiOiJoS3h5VC1RbV9yTWx4ZlFLX0tnQlZGLTVLZEEiLCJ0eXAiOiJhdCtqd3QifQ.eyJzdWIiOiJmY2NiNWNlYS1iODQwLWRlNmItZDk2NC0zYTEyODU4YmM0YjgiLCJ1bmlxdWVfbmFtZSI6InVzZXIxMTFAZXhhbXBsZS5jb20iLCJDb21wYW55SWQiOiIyIiwiVHlwZSI6Ik93bmVyIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidXNlcjExMUBleGFtcGxlLmNvbSIsImVtYWlsIjoidXNlcjExMUBleGFtcGxlLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjoiRmFsc2UiLCJwaG9uZV9udW1iZXJfdmVyaWZpZWQiOiJGYWxzZSIsIm9pX3Rrbl9pZCI6ImIxMmRlNzU1LTdiYWMtZDk2YS00NjEyLTNhMTMxYWZjZmYyNiIsImF1ZCI6IktUU0VzdGltYXRpb25BcHAiLCJqdGkiOiI0N2Q3NGE1Mi04OWFhLTQzNjMtODQxZC1iNWYzYTFjY2YzY2UiLCJpc3MiOiJodHRwczovL2VzdGF1dG9yaXphdGlvbi5rdHNkZXZ0ZWFtLmNvbS8iLCJleHAiOjE3MTgxMzgzNzcsImlhdCI6MTcxODEzNDc3N30.EsFsPidQP9JoGde0ZzMRvI7b38INdpYgjUNTf1l7Rzr43lY0CUYPTcVGOPo6F4tPD4Q3OVwuS0H253Uzo1NnHM-eA39zAvk9g88bm6YYlOJ6sioEhCjBHvTMZP1ALYxBWQRzJTOl9vjmhRIdoMATMSbSPVrEG3LOV56Q2cY5ypkGm6OGEZg7fmVZJB7I5RxifLUEbmpyYQJWSrmRyENS_WjMMtD88mP0GGrq7RwRO29yhJm_GI9A3gex69DSameNKjrruvb5y4nXrT1eZAwtufnfQJ9kVS7_w4gwA76pykAte-pllHmALXioyO_forRe6ftSXdd352h7PZTylw2djg"
  // localStorage.setItem('access_token_api', token)
  loginIDentityServer()
  fetchAccessToken()
})

const loginIDentityServer = () => {
  loginUser()
    .then(res => {
      localStorage.setItem('access_token_api', res.access_token)
      var base64Url = res.access_token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      const decodedToken = JSON.parse(jsonPayload);
      localStorage.setItem('companyId', decodedToken.CompanyId)
      console.log('decodedToken', decodedToken)
    })
    .catch(err => {
      console.error(err)
    })
}

const fetchAccessToken = () => {
  getAccessToken()
    .then(res => {
      localStorage.setItem('access_token', res.access_token)
      // accessToken.value = res.access_token
      console.log('res', res)
    })
    .catch(err => {
      console.error(err)
    })
}

const initAxiosInterceptor = () => {
  axios.interceptors.request.use(async request => {
    try {
      const accessToken = ''
      request.headers.Authorization = `Bearer ${accessToken}`
      request.headers["x-api-key"] = SERVER_ADDRESSES_KEY
    } catch (e) {
      request.headers["x-api-key"] = SERVER_ADDRESSES_KEY
    }
    request.url = `${BASE_URL}/${request.url}`

    return request
  })
  axios.interceptors.response.use(
    response => {
      return Promise.resolve(response)
    },
    error => {
      return Promise.reject(error)
    }
  )
}
</script>

