import axios from "axios"

export const getAccessToken = () => {
    return new Promise((resolve, reject) => {
      axios({
        headers: { 'content-type': 'application/x-www-form-urlencoded',
       'Authorization' : 'Basic MUtUNEE5ZndDd2Rma2hMOWtzUXYxYXFFd0ozU2NuRXE6ZUhKdVFCRXgxQURjMVdXZQ=='
        },
        data: {
            grant_type: 'client_credentials',
            scope: 'data:read data:write'
        },
        method: "post",
        url: "https://developer.api.autodesk.com/authentication/v2/token"
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getAllHubs = () => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        method: "get",
        url: "https://developer.api.autodesk.com/project/v1/hubs"
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getAllProjects = (hubId) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        method: "get",
        url: `https://developer.api.autodesk.com/project/v1/hubs/${hubId}/projects`
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getProjectContents = (projectId, folderId) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        method: "get",
        url: `https://developer.api.autodesk.com/data/v1/projects/${projectId}/folders/${folderId}/contents`
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getDocumentDetail = (projectId, documentId) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        method: "get",
        url: `https://developer.api.autodesk.com/data/v1/projects/${projectId}/items/${documentId}`
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const translateDocument = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
        },
        data,
        method: "post",
        url: 'https://developer.api.autodesk.com/modelderivative/v2/designdata/job'
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getMetaData = (urn) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
      },
      method: "get",
      url: `https://developer.api.autodesk.com/modelderivative/v2/designdata/${urn}/metadata`
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}


export const createProjectOnACC = (projectId, data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
      },
      method: "post",
      url: `https://developer.api.autodesk.com/data/v1/projects/${projectId}/folders`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createStorageOnACC = (projectId, data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
      },
      method: "post",
      url: `https://developer.api.autodesk.com/data/v1/projects/${projectId}/storage`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const generateSignedUrl = (objectIdwithFileExt) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
      },
      method: "get",
      url: `https://developer.api.autodesk.com/oss/v2/buckets/wip.dm.prod/objects/${objectIdwithFileExt}/signeds3upload`,
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const uploadFileToSignedUrl = (url, data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const generateObjectAfterSignedUrl = (objectIdwithFileExt, data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
      },
      method: "post",
      url: `https://developer.api.autodesk.com/oss/v2/buckets/wip.dm.prod/objects/${objectIdwithFileExt}/signeds3upload`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createUploadedFileVersion = (projectId, data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token')}`
      },
      method: "post",
      url: `https://developer.api.autodesk.com/data/v1/projects/${projectId}/items`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}
